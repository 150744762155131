.headerTitle {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 1.4rem;
  color: var(--secondary-dark-color);
  font-weight: bolder;
  text-shadow: 1px 1px 5px #0000008c;
  padding: 0;
  margin: 0;
  margin-left: 8px;
}

.desk_logo {
  margin-left: 10px;
  width: 100%;
  max-width: 200px;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.headerLogoIcon {
  font-size: 26px;
  margin-right: 8px;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 50%;
  }
  .toolbar {
    padding: 0 10px;
  }
  .headerLogoIcon {
    font-size: 20px;
    margin: 0 3px;
  }
  .headerUserBtn {
    font-size: 10px !important;
    width: 65% !important;
  }
}
@media only screen and (max-width: 990px) {
}
