.layoutTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
}

.layoutContent {
  background: white;
  padding-top: 95px;
}

.mainContentWrapper {
  margin: 15px;
  margin-bottom: 30px;
  background: whitesmoke;
  flex: 1;
  box-shadow: 2px 2px 10px #4c60606b;
}

.layoutTitleText {
  font-size: 1.4rem;
  color: #ff8045;
}
