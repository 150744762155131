.loginPageWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* background-color: #a28567; */
  background-image: linear-gradient(
    208deg,
    rgb(255 128 69 / 40%),
    rgb(57 142 220 / 40%)
  );
}

.pageHeading {
  text-align: center;
  color: black;
  margin-top: 5%;  
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(255 255 255 / 50%);
  align-items: center;
  width: 30%;
  padding: 50px 20px;
  border-radius: 25px;
}

.formStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.loginBtn {
  display: inline-block;
  color: #ffffff;
  padding: 8px 45px;
  border-radius: 30px;
  font-size: 18px;
  box-shadow: 0px 8px 15px #00000036;
  background: #398edc;
  border: none;
  transition: all 300ms ease-in-out;
}

.loginBtn:hover {
  background: #253b5f;
}

.loginBtn:disabled {
  background: #71767c;
}

.textFieldWrapper {
  margin-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textField {
  padding: 10px 33px;
  border-radius: 33px;
  border: none;
  box-shadow: 0px 8px 15px #00000036;
}

.logo {
  margin-bottom: 30px;
}
