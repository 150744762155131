body {
  padding: 0 !important;
  overflow: auto !important;
}
a,
a:link,
a:visited,
a:active,
a:hover,
button {
  text-decoration: none;
  outline: none;
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

img {
  max-width: 100%;
}
input,
select,
textarea {
  width: 100%;
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}
:root {
  --white: #fff;
  --green: #0f393b;
  --light_green: #f6f5ef;
  --orange: #ff8045;
  --yellow: #edcb50;
  --grey: #f1f1f1;
  --font-55: 55px;
  --font-40: 40px;
  --font-36: 36px;
  --font-30: 30px;
  --font-28: 28px;
  --font-26: 26px;
  --font-24: 24px;
  --font-20: 20px;
  --font-18: 18px;
  --font-16: 16px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
img {
  width: 100%;
}
p {
  font-size: 16px;
  line-height: 26px;
}
.com_btn {
  font-size: var(--font-18);
  color: var(--green);
  text-decoration: none;
  text-transform: inherit;
  padding: 8px 20px;
}

.com_btn:hover {
  color: var(--white);
  background: var(--orange);
}
a {
  text-decoration: none;
}

/* -------------------------------------------------------------------------------------------------- */

/* header css start here desktop 3-7 */

.comntitle {
  font-size: var(--font-30);
  font-weight: 500;
  color: var(--orange);
  margin-bottom: 25px;
}
.Layout_layoutTitleWrapper__GVRWR {
  padding: 15px 0;
  margin: 30px 0;
}
.Layout_layoutTitleWrapper__GVRWR .comntitle {
  margin: 0;
}
.MuiToolbar-root .css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root {
  display: none;
}

.mobile_div a {
  display: inline-block;
}

.mobile_logo {
  display: block;
}
.mobile_div img {
  max-width: 200px;
  margin-left: 10px;
}
.mobile_div > button > i {
  color: var(--green);
}
.css-1w90l2p-MuiPaper-root-MuiAppBar-root {
  position: fixed !important;
  background-color: #f6f5ef !important;
  box-shadow: 0px 0px 10px #0000003b !important;
}
.profile_div .btn_noti {
  width: auto;
  height: auto;
  background: none;
}

.Header_headerLogoIcon__1LSxm {
  font-size: var(--font-24);
}
header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
}
header ul > li > a {
  color: var(--green);
  text-decoration: none;
  font-size: var(--font-18);
}
header ul > li i {
  font-size: var(--font-18);
  color: #ff8045;
}
header ul > li > a:hover {
  color: var(--orange);
}
.subMenuLinksWrapper > li {
  margin-bottom: 8px;
}
.subMenuLinksWrapper {
  flex-direction: column;
  gap: unset;
  position: absolute;
  top: 100%;
  background-color: #0c4e51;
  z-index: 999;
  padding: 15px;
}
.subMenuLinksWrapper a {
  color: #fff;
}

.profile_div {
  display: flex;
  align-items: center;
  gap: 30px;
}

.profile_div .btn_noti {
  border: none;
}

.MuiToolbar-root .headerUserBtn {
  color: var(--green);
}

.Header_headerLogoIcon__1LSxm,
.profile_div .btn_noti i {
  font-size: var(--font-18);
  color: var(--orange);
}
.btn_noti {
  position: relative;
}
/* .css-3bmhjh-MuiPaper-root-MuiPopover-paper */

#notification-menu .MuiPaper-root {
  width: 320px;
  max-height: 350px !important;
  margin-top: 15px;
}
/* popup css start here */
#notification-menu .MuiPaper-root li {
  font-size: 15px;
}
#notification-menu .MuiPaper-root li:nth-child(6) {
  border: none !important;
}
#notification-menu .MuiPaper-root .com_btn {
  display: table;
  margin: auto;
  color: var(--orange);
}
#notification-menu .MuiPaper-root .com_btn:hover {
  color: var(--green);
}
.css-1eaqvnp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper:before,
.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: var(--light_green) !important;
}
.fa-user-circle {
  color: var(--green);
}
.fa-sign-out-alt:before {
  color: var(--orange);
}
/* popup css end here */

/* header css start end desktop 3-7 */
/* -------------------------------------------------------------------------------------------------- */
/* dashboard css start end desktop 3-7 */
.contetn_table .rdg-row:nth-child(even),
.contetn_table tr:nth-child(even) {
  background-color: #f6f5ef;
}
.contetn_table .rdg-row:nth-child(odd),
.contetn_table tr:nth-child(odd) {
  background-color: #fff;
}

.contetn_table tr:hover {
  background-color: #f6f5ef !important;
}
.contetn_table .rdg-header-row,
.contetn_table > thead tr:nth-child(1) th {
  background: rgb(15 57 59) !important;
}
.rdg-cell {
  box-shadow: none;
  border: none;
}

.dashboard_wrapper {
  margin: 50px 0;
  padding: 30px;
  border-radius: 10px;
  overflow: hidden;
  background: whitesmoke;
  flex: 1;
  box-shadow: 2px 2px 10px #4c60606b;
}

.dashboard_wrapper h3,
.table_box h4 {
  font-size: var(--font-28);
  margin-bottom: 50px;
  color: var(--green);
}
.dashboard_wrapper h3:after,
.table_box h4:after {
  content: " ";
  width: 7%;
  height: 3px;
  background-color: #edcb50;
  display: block;
  margin-top: 5px;
}
.eye_icon {
  font-size: var(--font-16);
  color: orangered;
  cursor: pointer;
}

.prepration {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.prepration > li {
  width: calc(49% - 3px);
  border-radius: 25px;
}
.prepration > li:nth-child(1) {
  background-color: #ea7f4a;
}
.prepration > li:nth-child(2) {
  background-color: #edcb50;
}
.prepration > li:nth-child(1) .client_box i {
  color: var(--orange) !important;
}
.prepration > li:nth-child(2) .client_box i {
  color: var(--yellow) !important;
}
.client_box {
  display: flex;
  gap: 30px;
  padding: 30px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.prepration > li:nth-child(1) > .client_box,
.prepration > li:nth-child(2) > .client_box {
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  background-size: 100px;
  padding: 15px;
}
.prepration > li:nth-child(1) > .client_box {
  background-image: url(../images/card_one.png), url(../images/card_two.png);
}
.prepration > li:nth-child(2) > .client_box {
  background-image: url(../images/card_four.png), url(../images/card_three.png);
}

.tag_line h6 {
  font-size: var(--font-24);
  color: white;
  font-weight: 700;
}

.client_box i {
  font-size: 30px;
  color: #253b5f !important;
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client_box span {
  font-size: var(--font-18);
  color: white;
  margin-bottom: 10px;
  display: block;
}
.mainfdiv {
  padding: 15px;
}
.slider_item {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px #0000002e;
  cursor: pointer;
}
.slider_item > p {
  font-weight: 600;
  padding: 15px 15px 0 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.slide_img img {
  height: 250px;
  object-fit: cover;
}
.slider-container .slick-next:before,
.slider-container .slick-prev:before {
  color: var(--green);
  font-size: var(--font-24);
}
.slider-container .com_btn {
  background-color: var(--orange);
  color: white;
  border-radius: 10px;
  display: table;
  margin: 50px auto 0;
}
.slider-container .com_btn:hover {
  background-color: var(--green);
}
/* dashboard css start end desktop 3-7 */

/* footer css start here */
footer {
  background-color: var(--green);
  padding-top: 30px;
  margin-top: 50px;
}
.footer_logo > a {
  display: inline-block;
}

.ft_link,
.app_img {
  display: flex;
  justify-content: end;
  gap: 30px;
}
.ft_link a {
  font-size: var(--font-16);
  text-decoration: none;
  color: white;
  display: block;
  transition: 0.5s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.ft_link a:hover {
  color: var(--orange);
}

.app_img {
  margin-top: 20px;
}
.app_img img {
  max-width: 150px;
}
footer p {
  color: white;
  text-align: center;
  border-top: 1px solid #dedede;
  margin-top: 20px;
  padding: 15px 0;
}

footer p > a {
  color: white;
}
footer p > a:hover {
  color: var(--orange);
}
/* footer css end here */

/*--------------------- Temporary Documents css start here 5-7-24--------------------- */

.upload_btn {
  width: 100%;
}

.fa-trash-alt,
.fa-download {
  color: var(--orange);
  cursor: pointer;
}
/*--------------------- Temporary Documents css end here 5-7-24--------------------- */

/*--------------------- Temporary Documents css end here 9-7-24--------------------- */
.temp_File {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.temp_File li {
  width: calc(33.33% - 15px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ff8045;
  padding: 7px 15px;
}
.temp_File li:hover {
  background: var(--orange);
  color: var(--white);
  border: 1px solid var(--orange);
}
.temp_File li:hover i {
  color: var(--white);
}
.temp_File li h5 {
  font-size: var(--font-18);
  word-break: break-all;
}
.css-82cuxn-MuiButtonBase-root-MuiButton-root.com_btn {
  font-size: var(--font-16);
  color: var(--white);
  text-decoration: none;
  text-transform: inherit;
  padding: 5px 15px;
}

.css-82cuxn-MuiButtonBase-root-MuiButton-root.com_btn:hover {
  color: var(--white);
  background: var(--orange);
}
.css-h4y409-MuiList-root {
  padding: 15px !important;
}
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button.css-18ii6ut-MuiButtonBase-root-MuiListItem-root {
  border: 1px solid #ddd;
  margin: 5px 0;
  background: #f6f5ef;
}

.subheading {
  font-size: var(--font-24) !important;
  color: var(--orange);
  text-transform: capitalize;
}

.task_detail {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.workcode,
.username {
  width: 30%;
}
.username div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border-bottom: 1px solid var(--grey);
  margin-bottom: 15px;
}
.username div:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.username div p i {
  font-size: var(--font-24);
  margin-right: 15px;
}

.task_detail h6 {
  font-size: var(--font-18);
  color: var(--green);
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 1px solid;
}
.Document_Upload {
  max-width: 350px;
  margin: auto;
  background: #f6f5ef;
  padding: 20px;
  box-shadow: 0 0 10px #c0c0c0;
  border-radius: 10px;
}
.uploadfile {
  margin: 10px 0;
}
.Document_Upload label {
  color: var(--green);
  font-weight: 500;
}
.Document_Upload input {
  background: var(--white);
}
.Document_Upload input:focus {
  border: 1px solid var(--orange);
}
.Document_Upload button {
  border: none;
  background: var(--orange);
  color: var(--white);
  display: table;
  margin: 20px auto 0;
  padding: 7px 20px;
  border-radius: 5px;
  font-weight: 600;
}
.Document_Upload button:hover {
  background: var(--green);
  color: var(--white);
}
.footer_logo img {
  max-width: 200px;
}
.Login_formWrapper__3_DoM {
  width: 100%;
  max-width: 390px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
.Login_formStyles__2sp0R {
  align-items: initial;
  width: 100%;
}
.Login_formStyles__2sp0R img {
  max-width: 200px;
}
.Login_formStyles__2sp0R .logo {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.error {
  border: 1px solid red;
}
.logindiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.logindiv a {
  color: var(--green);
}
.logindiv a:hover {
  color: var(--orange);
}
.Login_loginPageWrapper__3RrFh button {
  background: var(--orange) !important;
  color: var(--white);
}

.Login_loginPageWrapper__3RrFh input {
  font-size: var(--font-16);
  padding: 5px 15px;
  height: 50px;
}
.Login_loginPageWrapper__3RrFh h1 {
  font-size: var(--font-24);
}
.forgorbtndiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgorbtndiv a {
  color: var(--green);
}
.Login_formWrapper__3_DoM h1 {
  font-size: var(--font-24);
  margin-bottom: 20px;
}
input.otpInputStyle {
  width: 40px !important;
  height: 40px;
  padding: 5px;
}

.Login_formWrapper__3_DoM input {
  margin-bottom: 10px;
}

.fileView_btn {
  display: flex;
  justify-content: space-between;
}

/* ------------------------------------------------------- 

RESPONSIVE 3-7 

---------------------------------------------------------- */

@media only screen and (min-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1640px;
    margin: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1590px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 98%;
  }

  .prepration > li {
    width: calc(49% - 4px);
  }

  .dashboard_wrapper h3,
  .table_box h4 {
    margin-bottom: 30px;
  }
  .slider-container .com_btn {
    margin: 30px auto 0;
  }
  .task_detail {
    min-height: 320px;
  }
}

@media only screen and (max-width: 1190px) {
  :root {
    --font-40: 30px;
    --font-30: 28px;
    --font-28: 26px;
    --font-26: 24px;
    --font-24: 20px;
    --font-20: 18px;
    --font-18: 16px;
    --font-16: 15px;
  }

  .Header_logo__2CIsP {
    max-width: 150px;
  }
  .profile_div {
    gap: 3px;
  }
  .prepration > li {
    width: calc(49% - 7px);
  }
  .dashboard_wrapper {
    padding: 30px;
    margin: 30px 0;
  }
  .dashboard_wrapper h3,
  .table_box h4 {
    margin-bottom: 30px;
  }
  .footer_logo img {
    max-width: 200px;
  }
  footer {
    background-color: var(--green);
    padding-top: 30px;
    margin-top: 30px;
  }
  footer p {
    margin-top: 30px;
  }
  .slider-container .com_btn {
    margin: 15px auto 0;
  }
}
@media only screen and (max-width: 990px) {
  p {
    font-size: var(--font-16);
    line-height: 24px;
  }
  .manu_bar {
    display: none;
  }
  .MuiToolbar-root .css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root {
    display: block;
  }
  .mobile_div {
    display: flex;
  }
  .prepration > li {
    width: calc(49% - 10px);
  }
  .client_box {
    padding: 15px;
    flex-wrap: wrap;
  }
  .dashboard_wrapper {
    padding: 15px;
  }
  .dashboard_wrapper h3,
  .table_box h4 {
    margin-bottom: 15px;
  }
  .profile_div .MuiButtonBase-root span {
    display: none;
  }
  /* mobile css here */
  .NavigationSidebar_menuLinksWrapper__1bsNA {
    background-color: var(--green);
  }
  .mobile-logo {
    background-color: var(--light_green);
    padding: 30px 40px;
  }
  .mobile-logo > a {
    display: block;
    text-align: center;
  }
  .mobile-logo img {
    max-width: 150px;
  }
  .fa-sign-out-alt:before {
    color: var(--orange);
  }

  /* mobile css end here */
}
@media only screen and (max-width: 767px) {
  .Layout_layoutTitleWrapper__GVRWR .comntitle {
    margin: 15px 0;
  }
  .prepration > li {
    width: 100%;
  }
  .slick-prev {
    left: -15px;
  }
  .slick-next {
    right: -15px;
  }
  .ft_link,
  .app_img {
    gap: 2px;
  }
}
@media only screen and (max-width: 525px) {
  .mobile_div img {
    max-width: 130px;
    margin: 10px 0;
  }
  .footer_logo {
    text-align: center;
  }
  .footer_logo img {
    max-width: 150px;
  }
  .ft_link,
  .app_img {
    justify-content: center;
    margin-top: 15px;
  }
}
