.dialogBtnCancel {
  box-shadow: none !important;
  color: white !important;
  padding: 5px 20px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.dialogBtnCancel:hover {
  box-shadow: none !important;
}

.dialogBtnAccept {
  background: #398edc !important;
  float: right !important;

  box-shadow: none !important;

  padding: 5px 20px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.dialogBtnAccept:hover {
  background: #215b91 !important;
  box-shadow: none !important;
}

.dialogBtnAccept:disabled {
  background: #71767c !important;
  color: white !important;
  box-shadow: none !important;
}
