* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-primary-color: #3e8787;
  --main-light-color: #c8e3d4;
  --main-dark-color: #042f2f;

  --secondary-primary-color: #e2d7b0;
  --secondary-light-color: #f6eabe;
  --secondary-dark-color: #f6d7a7;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(51, 51, 51, 0.075);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.041);
  border-radius: 10px;
  background-color: rgba(238, 238, 238, 0.782);
}

html {
  height: 100%;
}
body {
  min-height: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

#dms_user {
  height: 100vh;
}
