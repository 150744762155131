.drawerWrapper {
  height: 100%;
  width: 18vw;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menuHeaderWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  background: #ff8045;
  padding-bottom: 5px;
}

.menuHeaderText {
  color: white;
  font-weight: bold;
  /* letter-spacing: 2px; */
  text-transform: uppercase;
  margin-top: 6%;
  margin-bottom: 6%;
}

.menuLinksWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
}

.menuLink {
  list-style: none;
  text-decoration: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  transition: 0.5s;
}

/* .menuLink.menuLinkSelected {
  opacity: 1;
  color: white;
  transform: translateX(8px);
} */

.menuLink:hover {
  color: var(--orange) !important;
  transform: translateX(8px);
}
.menuLink i {
  color: var(--orange);
}
/* .menuIcon {
  color: #398edc;
} */

.menuIcon:hover {
  color: inherit !important;
}

.menuIconSelected {
  color: white;
}
.menuIconSelected:hover {
  color: white !important;
}

.subMenuLinksWrapper {
  padding-left: 1.5rem;
}

.backdrop {
  z-index: 1200;
  color: #fff;
}
