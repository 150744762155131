.dataGridRow {
  background: whitesmoke;
  border: none;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  text-align: center;
  border-radius: 10px;
  margin: 2px;
  height: 100%;
  /* overflow-x: auto; */
  /* width: 100%; */
  /* max-height: 67vh; */
}

.rdg-header-row {
  background: whitesmoke;
}

.rdg-header-row {
  background: rgb(57 142 220);
  color: white;
}

.rdg-header-sort-cell {
  cursor: pointer;
  background: transparent;
  margin: 0;
  padding: 0;
  color: white;
  /* border: none; */
}

.c1wupbe700-beta7[aria-selected="true"] {
  box-shadow: inset 0px 0px 10px var(--secondary-primary-color);
}

.rdg-cell {
  /* width: 100%; */
  /* height: 100%; */

  /* margin: 0; */
  /* padding: 0 10px; */

  color: black;

  border: 1.5px double ivory;
  border-bottom: 2px solid #a0b3b338;

  display: flex;
  justify-content: center;
  align-items: center;
}

.rdg-row {
  background: whitesmoke;
}

.rdg-row:hover {
  background: #ff80451a;
}

input[type="search"] {
  padding: 5px 10px;
  border: 1px solid var(--main-primary-color);
  border-radius: 5px;
  font-size: 0.8rem;
}

input[type="search"]:focus {
  outline: 3px solid #ff8045;
  /* box-shadow: 1px 1px 4px var(--main-primary-color); */
}

.MuiTablePagination-selectLabel {
  margin: 0;
}
.MuiTablePagination-displayedRows {
  margin: 0;
}

.exportBtn:hover {
  color: white !important;
}

.exportCsv {
  background: #379529 !important;
  padding: 8px !important;
  margin-right: 5px !important;
}
.exportCsv:hover {
  background: #226418 !important;
}

.exportPdf {
  background: #f4542e !important;
  padding: 8px !important;
}
.exportPdf:hover {
  background: #b33c1e !important;
}
